export const columnsProvincia = [
  {
    title: "PROVINCIA",
    dataIndex: "nombre",
    key: "nombre",
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    sortDirections: ["descend", "ascend"],
    width: 130
  },
  {
    title: "NUM",
    dataIndex: "numerador",
    key: "numerador",
    sorter: (a, b) => a.numerador - b.numerador
  },
  {
    title: "DEN",
    dataIndex: "denominador",
    key: "denominador",
    sorter: (a, b) => a.denominador - b.denominador
  },
  {
    title: "RESULTADO",
    dataIndex: "porcentaje",
    key: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    width: 150
  }
];

export const columnsDistrito = [
  {
    title: "DISTRITO",
    dataIndex: "nombre",
    key: "nombre",
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    sortDirections: ["descend", "ascend"],
    width: 150
  },
  {
    title: "NUM",
    dataIndex: "numerador",
    key: "numerador",
    sorter: (a, b) => a.numerador - b.numerador
  },
  {
    title: "DEN",
    dataIndex: "denominador",
    key: "denominador",
    sorter: (a, b) => a.denominador - b.denominador
  },
  {
    title: "RESULTADO",
    dataIndex: "porcentaje",
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    key: "porcentaje",
    width: 150
  }
];

export const columnsEstablecimiento = [
  {
    title: "ESTABLECIMIENTO",
    dataIndex: "nombre",
    key: "nombre",
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    sortDirections: ["descend", "ascend"],
    width: 120
  },
  {
    title: "NUM",
    dataIndex: "numerador",
    key: "numerador",
    sorter: (a, b) => a.numerador - b.numerador
  },
  {
    title: "DEN",
    dataIndex: "denominador",
    key: "denominador",
    sorter: (a, b) => a.denominador - b.denominador
  },
  {
    title: "RESULTADO",
    dataIndex: "porcentaje",
    key: "porcentaje",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    slots: { customRender: "porcentaje" },
    width: 150
  }
];
